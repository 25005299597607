<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item to="/">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item to="/about">
          <v-list-item-action>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-action>
          <v-list-item-title>About Us</v-list-item-title>
        </v-list-item>
        <v-list-item to="/get-involved">
          <v-list-item-action>
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-list-item-action>
          <v-list-item-title>Get Involved</v-list-item-title>
        </v-list-item>

        <a
          class="donate-black"
          href="https://www.aplos.com/aws/give/BibleStudyTwo1"
          target="_blank"
        >
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-wallet-giftcard</v-icon>
            </v-list-item-action>
            <v-list-item-title> Donate </v-list-item-title>
          </v-list-item>
        </a>
        <v-list-item to="/connect">
          <v-list-item-action>
            <v-icon>mdi-at</v-icon>
          </v-list-item-action>
          <v-list-item-title>Connect</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="blue-grey" dark>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndDown"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <router-link class="d-flex align-center" to="/">
        <v-img src="@/assets/logo.svg" width="60" class="mr-2" />
        <v-img src="@/assets/logo-text.svg" width="150" />
      </router-link>

      <v-spacer />

      <template v-if="$vuetify.breakpoint.lgAndUp">
        <v-btn text rounded to="/">Home</v-btn>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text rounded to="/about" v-on="on"> About Us </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn to="/about#whats-important" text rounded>
                What's Important to Us
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#schedule" text rounded> Schedule </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#high-school-boys" text rounded>
                High School Boys
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#high-school-girls" text rounded>
                High School Girls
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#middle-school" text rounded>
                Middle School Boys
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#bsx" text rounded> Bible Study Extreme </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#leadership" text rounded> Leadership </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#directors" text rounded>
                Board of Directors
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#history" text rounded> Our History </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/about#future" text rounded> Our Future </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text rounded to="/get-involved" v-on="on">
              Get Involved
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn to="/get-involved#churches" text rounded>Churches</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/get-involved#prayer" text rounded>Prayer</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/get-involved#food" text rounded>Food</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/get-involved#other" text rounded>Other</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn to="/get-involved#partners" text rounded>Partners</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn text rounded>
          <a
            class="donate-white"
            href="https://www.aplos.com/aws/give/BibleStudyTwo1"
            target="_blank"
            >Donate</a
          >
        </v-btn>
        <v-btn text rounded to="/connect">Connect</v-btn>
      </template>
    </v-app-bar>

    <div class="background-img">
      <v-img src="@/assets/logo-opacity.svg" height="90%" contain></v-img>
    </div>

    <v-main>
      <router-view />
    </v-main>
    <v-card flat color="grey darken-4" dark height="5rem">
      <v-card-text
        class="d-flex justify-center align-center"
        style="height: 100%"
      >
        <a href="https://www.facebook.com/biblestudytwo">
          <v-icon class="pa-2" large>mdi-facebook</v-icon>
        </a>
        <a href="https://instagram.com/biblestudytwo">
          <v-icon class="pa-2" large>mdi-instagram</v-icon>
        </a>
        <a href="https://twitter.com/biblestudytwo">
          <v-icon class="pa-2" large>mdi-twitter</v-icon>
        </a>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "biblestudytwo",
  data: () => ({ drawer: false }),
};
</script>

<style scoped>
.background-img {
  position: fixed;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
}

.donate-white,
.donate-white:link,
.donate-white:active,
.donate-white:hover,
.donate-white:visited {
  color: white;
  text-decoration: none;
}

.donate-black,
.donate-black:link,
.donate-black:active,
.donate-black:hover,
.donate-black:visited {
  color: black;
  text-decoration: none;
}
</style>
